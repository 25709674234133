var alertPanel = {
    divId: 'alertPanel',
    alert: function (content) {
        var convertedContent = strBR(content);
        dynamic.showOverlay('alertPanelOverlay', 30);
        dynamic.setDivIdContent('alertPanelContent', convertedContent);
        var top = $(window).scrollTop() + 100;
        dynamic.centerDiv(this.divId);
        $('#' + this.divId).css({
            top: top + 'px'
        });
        var tempThis = this;
        $('#' + this.divId).fadeIn(function () {
            $(window).keyup(function (e) {
                tempThis.onkeyup(e);
            });
        });
    },
    close: function () {
        $('#' + this.divId).fadeOut(null, function () {
            dynamic.setDivIdContent('alertPanelContent', '');
        });
        dynamic.hideOverlay('alertPanelOverlay');
        $(window).unbind('keyup');
        this.onclose();
    },
    onkeyup: function (event) {
        if (isEnterEvent(event)) {
            this.close();
        }
    },
    onclose: function () {
        // event handlers
    }
};
